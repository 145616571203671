<template>
  <div class="add-phone-inner-form">
    <SfHeading
      v-if="!simple"
      :level="3"
      :title="$t('Add additional phone')"
      class="add-phone-header"
    />

    <SfOModal
      v-if="simple"
      :visible="inputSmsCode"
      :is-bar-back="true"
      :is-bar-close="false"
      :title="$t('Add additional phone')"
      @close="closeModal"
      @back="closeModal"
    >
      <MCheckSms
        :phone="newPhoneNumber"
        @onCompleteSMS="onCompleteSMS"
        @onEditPhone="edit"
        :is-code-wrong="isCodeWrong"
        :another-error="anotherError"
      />
      <ALoadingSpinner
        v-show="isShowLoader"
        key="spinner"
        :is-absolute-position="true"
      />
    </SfOModal>

    <template v-if="inputSmsCode && !simple">
      <MCheckSms
          :phone="newPhoneNumber"
          @onCompleteSMS="onCompleteSMS"
          @onEditPhone="edit"
          :is-code-wrong="isCodeWrong"
          :another-error="anotherError"
      />
    </template>
    <form
      v-else
      class="form"
      @submit.prevent="sendSms"
    >
      <div>
        <div
          v-if="!simple"
          class="type-new-phone"
        >
          {{ $t('Type a new phone number') }}
        </div>
        <SfInput
          name="phone"
          v-model="newPhoneNumber"
          :label="`${$t('Phone number')} *`"
          class="sf-input--filled form__element"
          v-mask="'+38 ### ### ## ##'"
          type="tel"
          data-transaction-name="Additional Phone - Field"
          :valid="isNewPhoneNumberValid"
          :error-message="phoneErrorMessage"
        />
      </div>
      <SfButton
          v-if="!simple"
          :disabled="isSubmitDisable"
          data-transaction-name="Additional Phone - Save"
          class="sf-button--primary form__button"
          type="submit"
      >
        {{ $t('Save') }}
      </SfButton>
      <div class="add-phone-actions" v-else>
        <SfButton
            :disabled="isSubmitDisable"
            data-transaction-name="Additional Phone - Save"
            class="sf-button--primary sf-button--full-width-mobile"
            type="submit"
        >
          {{ $t('Get code') }}
        </SfButton>

        <SfButton
          data-transaction-name="Additional Phone - Cancel"
          class="form__cancel sf-button--full-width-mobile"
          @click.prevent="cancel"
        >
          {{ $t('Cancel') }}
        </SfButton>
      </div>
    </form>
    <ALoadingSpinner
      v-show="isShowLoader"
      key="spinner"
      :is-absolute-position="true"
    />
  </div>
</template>

<script>
import {
  SfHeading,
  SfButton
} from '@storefront-ui/vue';
import SfInput from 'theme/components/storefront-override/SfInput'
import { ModalList } from 'theme/store/ui/modals'
import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'
import { getCleanedPhone } from 'theme/helpers/text';
import { uniqInAccount, uniqInAdditional } from 'theme/helpers/validation';
import MCheckSms from 'theme/components/molecules/m-check-sms'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import SfOModal from 'theme/components/storefront-override/SfOModal.vue';
import SmsBlock from 'theme/mixins/SmsBlock';
import { groups, sendToLogs } from 'theme/helpers/web-logging';

export default {
  name: 'MAddAdditionalPhone',
  components: {
    SfOModal,
    SfHeading,
    SfInput,
    SfButton,
    MCheckSms,
    ALoadingSpinner
  },
  directives: {
    mask
  },
  data () {
    return {
      newPhoneNumber: '+38',
      inputSmsCode: false,
      smsCode: '',
      isCodeWrong: false,
      anotherError: null,
      isShowLoader: false
    }
  },
  props: {
    simple: {
      type: Boolean,
      default: () => false
    }
  },
  mixins: [SmsBlock],
  methods: {
    ...mapActions({
      closeModalHandler: 'ui/closeModal',
      refreshUserData: 'user/me',
      sendOtp: 'userInfo/sendOtp',
      addAdditionalPhone: 'userInfo/addAdditionalPhone',
      spawnNotification: 'notification/spawnNotification'
    }),
    cancel () {
      this.$emit('cancel')
    },
    async sendSms () {
      if (!this.checkCanSendSMS()) return

      this.isShowLoader = true

      try {
        const response = await this.sendOtp(this.cleanedPhone)
        this.isShowLoader = false

        if (response.result?.status === 'success') {
          this.inputSmsCode = true
        } else {
          this.handleFailure(this.$t('Unexpected error when sending a code by SMS'));

          sendToLogs(
            groups.Auth,
            'otp:mAdditionalPhone:sendSms:not:success',
            {
              code: response.code,
              result: response?.result || null
            },
            response?.transparentId
          )
        }
      } catch (error) {
        this.handleFailure(this.$t('Unexpected error when sending a code by SMS'));
      }
    },
    async addNewPhone () {
      this.isCodeWrong = false;
      this.anotherError = null;
      this.isShowLoader = true;

      try {
        const response = await this.addAdditionalPhone({
          newAdditionalPhone: this.cleanedPhone,
          otp: this.smsCode
        })

        if (response.result?.status === 'success') {
          await this.refreshUserData({ refresh: true, useCache: false })
          this.handleSuccess(this.$t('Additional phone is added'))
          this.$bus.$emit('checkout-after-adding-number', this.cleanedPhone)
          await this.closeModal({ name: ModalList.AddAdditionalPhone });
        } else if (response.result?.message) {
          this.anotherError = response.result?.message || ''
        }

        this.isCodeWrong = true;
      } catch (error) {
        this.isCodeWrong = true;
      } finally {
        this.isShowLoader = false;
      }
    },
    onCompleteSMS (value) {
      this.smsCode = value
      this.addNewPhone()
    },
    edit () {
      this.inputSmsCode = false
    },
    closeModal (name) {
      this.inputSmsCode = false
      this.closeModalHandler(name)
    },
    handleSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      });
      this.cancel()
    },
    handleFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result),
        action1: { label: this.$t('OK') }
      });
    }
  },
  computed: {
    isSubmitDisable () {
      return this.newPhoneNumber.length !== 17 || !this.isNewPhoneNumberValid
    },
    currentUser () {
      return this.$store.state.user.current
    },
    phones () {
      return this.currentUser?.additional_phones
    },
    cleanedPhone () {
      return getCleanedPhone(this.newPhoneNumber)
    },
    isNewPhoneNumberValid () {
      return !this.$v.newPhoneNumber.$invalid
    },
    phoneErrorMessage () {
      if (!this.$v.newPhoneNumber.uniqInAccount) return this.$t('This number is already in use on this account');
      if (!this.$v.newPhoneNumber.uniqInAdditional) return this.$t('This number is already in use in additional numbers');
      return '';
    }
  },
  validations () {
    return {
      newPhoneNumber: {
        uniqInAccount,
        uniqInAdditional
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.add-phone-inner-form {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (max-width: $mobile-max) {
    height: 100%;
  }

  .add-phone-header {
    margin-bottom: var(--spacer-20);

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-max) {
      height: 100%;
    }

    .add-phone-actions {
      display: flex;
    }

    .add-phone__summary {
      font-size: var(--font-sm);
      line-height: var(--font-size-17);
      color: var(--dark-gray);
      margin: 0 0 var(--spacer-12);
    }
    .exist-phone {
      display: flex;
      font-size: var(--font-sm);
      line-height: var(--font-size-17);
      margin-bottom: var(--spacer-5);
      justify-content: start;

      &__number {
        font-weight: var(--font-medium);
        margin-right: var(--spacer-20);
        min-width: px2rem(142);
      }

      &__action {
        cursor: pointer;
        color: var(--orange)
      }

      @media (max-width: $mobile-max) {
        justify-content: space-between;
      }
    }

    .type-new-phone  {
      margin: 0 0 var(--spacer-15);
      font-size: var(--font-sm);
      line-height: var(--font-size-17);
      color: var(--dark-gray);

      @media (max-width: $mobile-max) {
        color: var(--black);
        font-size: var(--font-size-13);
      }
    }

    .form__button {
      font-size: var(--font-size-18);
      line-height: var(--font-size-24);

      @media (max-width: $mobile-max) {
        margin-top: auto;
      }
    }

    .form__cancel {
      background: transparent!important;
      color: var(--orange);
      font-weight: 400;
    }

    ::v-deep {
      .sf-input {
        input {
          border: 1px solid var(--white);
          --input-bar-display: none;

          &:focus {
            border: 1px solid var(--orange);
            box-sizing: border-box;
          }

          &:focus-visible {
            border: 1px solid var(--orange);
            box-sizing: border-box;
          }
        }
      }
    }
  }

  ::v-deep {
    .sf-modal {
      --bar-padding: 0 var(--spacer-20);

      @media (min-width: $tablet-min) {
        --modal-width: 530px;
        --modal-top: 50%;
        --modal-left: 50%;
        --modal-bottom: none;
        --modal-right: none;
        --modal-transform: translate3d(-50%, -50%, 0);
        --modal-height: auto;
        --modal-content-padding: var(--spacer-50);
      }
    }

    .sf-bar {
      border-bottom: 2px solid var(--light-gray);

      div:nth-child(2) {
        flex-grow: 1 ;
        margin-left: var(--spacer-15);
      }

      @media (min-width: $tablet-min) {
        display: none;
      }
    }

    .sf-bar__title {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      line-height: var(--font-size-24);
      padding: var(--spacer-16) 0;
      white-space: nowrap;
    }

    .sf-modal__container {
      @media (max-width: $mobile-max) {
        height: 100%;
      }
    }

    .sf-modal__close {
      @media (max-width: $mobile-max) {
        display: none;
      }

      @media (min-width: $tablet-min) {
        display: inline-flex!important;
      }
    }

    .sf-modal__content {
      padding: var(--spacer-50);
      font-family: var(--font-family-inter);

      @media (max-width: $mobile-max) {
        height: 100%;
        padding: var(--spacer-20) var(--spacer-10) var(--spacer-20);
      }

      .sf-heading {
        padding: 0;

        &__title {
          font-size: var(--font-xl);
          font-weight: var(--font-medium);
        }
      }

      .sf-button {
        width: 100%;
      }
    }

    .sf-input__wrapper {
      margin-bottom: var(--spacer-4);
    }
  }
}
</style>
